import { createMuiTheme } from "@material-ui/core/styles"

// Need to instantiate new one to access to breakpoint in the primary theme
const defaultTheme = createMuiTheme()

export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1256,
      lg: 1920,
      xl: 2560,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        "@media(max-width: 1255px)": {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 50,
        textTransform: "none",
        padding: 16,
      },
      containedPrimary: {
        color: "#005646",
        boxShadow: "none",
        paddingTop: 12,
        paddingBottom: 12,
        "&:hover": {
          boxShadow: "0px 2px 22px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#F59BBB",
        },
      },
      containedSecondary: {
        color: "white",
        boxShadow: "none",
        padding: "9px 16px",
        fontWeight: "bold",
        fontSize: 14,
        "&:hover": {
          backgroundColor: "#1A8E96",
        },
      },
    },
    MuiCheckbox: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiCheckbox-colorSecondary .Mui-checked": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: "white",
    },
    primary: {
      main: "#F59BBB",
      light: "#F59BBB",
      dark: "#ecafaf",
    },
    secondary: {
      main: "#005646",
      light: "#00564638",
      dark: "#073331",
      contrastText: "#a4e7db",
    },
    yellow: "#FFD747",
    green: "#A4E7DB",
    inputBorder: "#DBDFE8",
    containerGrey: " #F6F6F6",
    pink: "#FF757B",
    text: {
      primary: "#1B1E1C",
      secondary: "#3E3E3E",
      disabled: "#ABABAB",
      success: "#81D89F",
      turquoise: "#1A8E96",
      noResult: "#787878",
      greyHome: "#D7D7D7",
    },
  },
  typography: {
    fontFamily: ["GT Welsheim Pro", "Arial", "sans-serif"],
    h1: {
      fontWeight: "900",
      fontSize: "44px",
      lineHeight: "110%",
      letterSpacing: "-1px",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "32px",
        lineHeight: "100%",
      },
    },
    h2: {
      letterSpacing: "-1px",
      fontWeight: 500,
      fontSize: "34px",
      lineHeight: "120%",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "26px",
        lineHeight: "120%",
      },
    },
    subtitle1: {
      color: "#ABABAB",
    },
    h3: {
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "120%",
    },
    body1: {
      fontSize: "19px",
      lineHeight: "120%",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "17px",
        lineHeight: "140%",
      },
    },
    body2: {
      fontSize: "16px",
      lineHeight: "120%",
    },
    caption: {
      fontSize: "13px",
      lineHeight: "140%",
      fontWeight: 400,
      color: "#ABABAB",
    },
  },
})
